//********************************************************************************
//* This file defines the Application Insights configuration for the application.
//* and global object to use when tracking events.
//********************************************************************************
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString:'InstrumentationKey=9370ba81-89ea-4d2e-9fd1-881534501394;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=f724668b-6cde-4497-9e3f-1327abf5b625',
        instrumentationKey: '9370ba81-89ea-4d2e-9fd1-881534501394',
        enableAutoRouteTracking: true, // adding track user navigation through the application
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
